@import "~core/abstract/all";
@import "~bootstrap/scss/mixins";

.b-text-and-btn {
    margin: auto;
    padding: 0;
     
    h2 {
        margin-bottom: 0;
    }
    p {
        margin: 24px auto;
    }

    .btn-flex-ctn{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5%;
    }


    @include media-breakpoint-up(sm) { 
        .btn-flex-ctn{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 5%;
        }
        
    }
}

.b-text-and-btn.product-feature{ 
    padding: 0 20px;

    @include media-breakpoint-up(ls) { 
        padding: 0;
    }

    .btn-flex-ctn{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 5%;

        @include media-breakpoint-up(ls) { 
            .b-hero-text-overlay__button{
                max-width: 100%;
                min-width: unset;
                font-size: 14px;
            }
        }
    }
}
